import EmberRouter from '@ember/routing/router';
import config from './config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function() {
  this.route('room', { path: '/:name' });

  // catchall for everything else
  this.route('not-found', { path: '/*not_found' });
  this.route('home', { path: '/' });
});
