import Application from '@ember/application';
import Resolver from 'ember-resolver';
import loadInitializers from 'ember-load-initializers';
import config from './config/environment';
import ResetScrollMixin from '@sessions/ember-shared/mixins/reset-scroll';
import SessionMixin from '@sessions/ember-shared/mixins/session';
import Component from '@ember/component';
import Controller from '@ember/controller';
import Route from '@ember/routing/route';

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
}

Component.reopen(SessionMixin);
Controller.reopen(SessionMixin);
Route.reopen(ResetScrollMixin, SessionMixin);

loadInitializers(App, config.modulePrefix);
