import { inject } from '@ember/service';
import Route from '@ember/routing/route';

export default class RoomRoute extends Route {
  @inject router;
  @inject session;
  @inject store;

  afterModel(model) {
    if (!model) {
      this.templateName = 'not-found';
    }
  }

  async beforeModel(transition) {
    super.beforeModel(...arguments);

    // safely handle load with errors
    await this.store.findRecord('telehealth-configuration', transition.to.params.name).then(() => {}, (error) => {
      if (error?.status?.toString() !== '404' && error?.code !== 'NotFoundError') {
        throw error
      }
    });
  }

  model(params) {
    return this.store.peekAll('telehealth-configuration').findBy('key', params.name?.toLowerCase());
  }

  setupController() {
    super.setupController(...arguments);
    this.session.set('currentTelehealthGuest', this.store.peekAll('telehealth-guest').firstObject);
  }
}
