import { inject } from '@ember/service';
import Route from '@ember/routing/route';

export default class HomeRoute extends Route {
  @inject location;

  redirect() {
    this.location.replace('https://www.sessionshealth.com');
  }
}
